<template>
  <page-layout class="discussion is-relative">
    <nav-locales class="locales-container"></nav-locales>
    <h1>{{ $t("session_discussion.title") }}</h1>

    <template v-if="isLoaded">
      <h2>{{ sessionName }}</h2>

<!--      &lt;!&ndash; Description visible only on speakers number is <> 1 &ndash;&gt;-->
<!--      <template v-if="speakers.length !== 1">-->
<!--        <p v-if="sessionDescription" class="session-description">-->
<!--          {{ sessionDescription }}-->
<!--        </p>-->
<!--      </template>-->

      <div v-if="speakers.length" class="session-speakers">
        <div class="session-speakers-group">
          <template v-if="speakers.length === 1">
            <session-speaker :speaker="speakers[0]">
<!--              <p v-if="sessionDescription">{{ sessionDescription }}</p>-->
            </session-speaker>
          </template>

          <template v-else>
            <session-speaker-alt v-for="speaker of speakers" :key="speaker.id" :speaker="speaker"></session-speaker-alt>
          </template>
        </div>
      </div>

      <div class="session-questions">
        <h3>
          <div class="d-inline-block position-relative">
            <span class="dropdown-toggle" data-toggle="dropdown">{{ sortQuestionsBy.label }}</span>
            <div class="dropdown-menu dropdown-menu-right">
              <div v-for="sortBy of sortByMethods" :key="sortBy.id" class="dropdown-item" @click="setQuestionsSortBy(sortBy)">
                {{ sortBy.label }}
              </div>
            </div>
          </div>
        </h3>
        <template v-if="sortedQuestions.length">
          <transition-group name="questions" tag="div">
            <session-question v-for="question of sortedQuestions" :key="question.id" :question="question"></session-question>
          </transition-group>
        </template>

        <template v-else>
          <p>{{ $t("session_discussion.empty_screen") }}</p>
        </template>
      </div>
    </template>

    <div v-else class="page-loader">
      <spinner></spinner>
    </div>
  </page-layout>
</template>

<script>
import PageLayout from "@/web/components/external/ExternalPageLayout";
import SessionSpeaker from "@/web/components/external/discussion/SessionSpeaker";
import SessionSpeakerAlt from "@/web/components/external/discussion/SessionSpeakerAlt";
import SessionQuestion from "@/web/components/external/discussion/SessionQuestion";
import Spinner from "@/web/components/external/Spinner";
import { mapState, mapActions, mapGetters } from "vuex";
import NavLocales from "@/web/components/locale/NavLocales";

const sortByMethods = [
  { id: "recent", label: "Recent questions", sortFunc: (a, b) => 0 },
  { id: "likes", label: "Liked questions", sortFunc: (a, b) => b.likes - a.likes },
];

export default {
  components: {
    NavLocales,
    PageLayout,
    SessionSpeaker,
    SessionSpeakerAlt,
    SessionQuestion,
    Spinner,
  },

  data() {
    return {
      sortByMethods: sortByMethods,
      sortQuestionsBy: sortByMethods[0],
    };
  },

  mounted() {
    const { eventSlug, componentId, sessionId } = this.routeParams;

    this.loadAgendaSession({ eventId: this.eventId, componentId, sessionId })
      .then(session => {
        return this.loadQuestions({ eventId: this.eventId, componentId, sessionId });
      })
      .then(questions => {
        return this.startPusherListening({ eventId: this.eventId, componentId, sessionId });
      });
  },

  methods: {
    ...mapActions("questions", ["loadQuestions", "loadAgendaSession", "startPusherListening"]),

    setQuestionsSortBy(sortBy) {
      this.sortQuestionsBy = sortBy;
    },
  },

  computed: {
    ...mapState(["eventId"]),
    ...mapState("questions", ["session", "questions"]),
    ...mapGetters("questions", ["isLoaded"]),

    sortedQuestions() {
      // Make copy as .sort method mutates the original array(!)
      const questions = [...this.questions];
      const sortFunc = this.sortQuestionsBy.sortFunc;

      return questions.sort(sortFunc);
    },

    speakers() {
      return this.session && this.session.speakers ? this.session.speakers : [];
    },

    sessionName() {
      return this.session ? this.session.name : "";
    },

    sessionDescription() {
      return this.session ? this.session.description : "";
    },

    routeParams() {
      return {
        eventSlug: this.urlSegments[0],
        componentId: Number(this.urlSegments[2]),
        sessionId: Number(this.urlSegments[4]),
      };
    },

    urlSegments() {
      return window.location.pathname.split("/").filter(x => !!x);
    },
  },
};
</script>

<style lang="scss" scoped>
.discussion h1 {
  margin: 0 0 10px 0;
}

.discussion h2 {
  margin: 0 0 40px 0;
  text-transform: uppercase;
  text-align: center;
  font-size: 24px;
  font-weight: 700;
}

.session-description {
  margin: 0 auto 40px auto;
  text-align: center;
  font-size: 20px;
  max-width: 500px;
}

.session-speakers {
  margin-bottom: 40px;
}

.session-speakers-group {
  margin: 0 0 -40px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.session-questions {
  margin: 0 60px 40px 60px;
  //max-width: 100%;
  //width: 100%;
  flex: 1 1;

  h3 {
    margin: 0 0 20px 0;
    padding: 0 0 10px 0;
    border-bottom: dotted 2px #041133;
    font-weight: 600;
    font-size: 26px;

    .dropdown-toggle,
    .dropdown-menu .dropdown-item {
      cursor: pointer;
    }
  }

  p {
    margin: 20px 0;
  }
}

.questions-enter-active,
.questions-leave-active {
  transition: all 400ms;
}

.questions-enter,
.questions-leave-to {
  opacity: 0;
  transform: translateY(10px);
}

.dropdown-item {
  padding: 0.5rem 1.5rem;
}

.locales-container {
  position: absolute;
  top: 1.7rem;
  right: 10vw;
}
</style>
